.eventCard {
    border: 2px solid $greyD;
    padding: 30px;
    border-radius: 0 50px 0 0;
    margin-bottom: 30px;
    
    h3 {
        font-family: $cera-book;
        font-size: 30px;
    }
    .details {
        margin: 30px 0;
        
        li {
            margin-bottom: 10px;
            img {
                max-width: 20px;
                display: inline-block;
                margin-right: 4px;
            }
            h6 {
                display: inline-block;
                position: relative;
                top: 4px;
                width: 80%;
            }
        }
    }
    p {
        font-size: 18px;
        line-height: 1.3em;
    }
    h5 {
        transition: $transition;
                
        &:hover {
            color: $green;
        }
    }
    
    &.no-events {
        border: none;
        
        h3 {
            color: #999;
            font-style: italic;
            text-align: center;
        }
    }
}