#footer {
    position: relative;
    height: 175px;
    background: $base;
    
    @include mobile {
        height: auto;
        padding: 40px 0;
    }
    @include small-mobile {
        height: auto;
        padding: 40px 0;
    }
    
    h3 {
        color: $white;
        text-transform: uppercase;
        position: relative;
        top: 70px;
        display: inline-block;
        
        @include tablet {
            top: 40px;
            left: 15px;
            display: block;
        }
        @include mobile {
            text-align: center;
            top: 0;
            display: block;
        }
        @include small-mobile {
            text-align: center;
            top: 0;
            display: block;
        }
    }
    
    .social {
        display: inline-block;
        position: relative;
        top: 60px;
        left: 20px;
        
        @include tablet {
            top: 60px;
            left: 15px;
            display: block;
        }
        @include mobile {
            top: 0;
            left: 0;
            display: block;
            text-align: center;
            margin: 15px auto;
        }
        @include small-mobile {
            top: 0;
            left: 0;
            display: block;
            text-align: center;
            margin: 15px auto;
        }
        
        li {
            display: inline-block;
            margin: 0 4px;
            
            img {
                max-width: 40px;
            }
        }
    }
    
    .logo {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 175px;
        background: $white;
        padding: 0 30px;
        
        @include mobile {
            display: none;
        }
        @include small-mobile {
            display: none;
        }
        
        &:before {
          height: 0;
          width: 0;
          content: "";
          display: block;
          position: absolute;
          border-bottom: 175px solid #FFF;
          border-left: 50px solid transparent;
          left: -50px;
        }
        
        img {
            height: 135px;
            position: relative;
            top: 20px;
        }
    }
}