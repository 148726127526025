.newsCard {
    background: $white;
    padding: 30px;
    border-radius: 0 50px 0 0;
    margin-bottom: 30px;
    
    h6 {
        text-transform: uppercase;
        margin-bottom: 60px;
    }
    .divi {
        width: 60px;
        height: 4px;
        background: $red;
        margin-bottom: 10px;
    }
    h3 {
        margin: 0 0 30px 0;
    }
    p {
        font-size: 18px;
        line-height: 1.3em;
    }
    h5 {
        margin-top: 60px;
        transition: $transition;
                
        &:hover {
            color: $blue;
        }
    }
}