.nav {
    .logo {
        background: $white;
        padding: 15px 20px;
        position: relative;
        z-index: 5;
        max-width: 275px;
        display: inline-block;
        
        @include tablet {
            max-width: 250px;
        }
        @include mobile {
            max-width: 225px;
        }
        @include small-mobile {
            max-width: 210px;
        }
        
        &:after {
          background: inherit;
          bottom: 0;
          content: '';
          display: block;
          height: 60%;
          left: 0;
          position: absolute;
          right: 0;
          transform: skewY(-5deg);
          transform-origin: 100%;
          z-index: 2;
        }
        
        img {
            display: block;
            margin: auto;
            position: relative;
            z-index: 6;
        }
    }
    .navItems {
        margin: 0;
        text-align: right;
        float: right;
        position: relative;
        top: 60px;
        transition: $transition;
        
        @include tablet {
            display: none;
        }
        @include mobile {
            display: none;
        }
        @include small-mobile {
            display: none;
        }
        
        &:hover li:not(:hover) {
            opacity: 0.3;
        }
        
        li {
            display: inline-block;
            margin-left: 35px;
            opacity: 1;
            transition: $transition;
            
            h5 {
                font-family: $cera-book;
                color: $white;
            }
            
            &.link {
                padding: 8px 0 6px 0;
            }
            &.contact {}
            &.share {
                position: relative;
                top: -3px;
                img {
                    max-width: 22px;
                }
            }
        }
    }
}