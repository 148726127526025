#header {
    background: $base;
    width: 100%;
    height: 90vh;
    position: relative;
    z-index: 1;
    margin-bottom: 120px;
    
    @include mobile {
        margin-bottom: 100px;
    }
    @include small-mobile {
        margin-bottom: 80px;
    }
    
    &:after {
      background: inherit;
      bottom: 0;
      content: '';
      display: block;
      height: 50%;
      left: 0;
      position: absolute;
      right: 0;
      transform: skewY(-5deg);
      transform-origin: 100%;
      z-index: -1;
    }
    
    .nav {
        position: relative;
        z-index: 999;
    }
    .wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        height: 95vh;
        position: absolute;
        top: 50px;
        left: 0;
        right: 0;
    }
    .contentBlock {
        text-align: center;
        max-width: 60%;
        margin: auto;
        position: relative;
        align-self: center;
        
        @include tablet {
            max-width: 70%;
        }
        @include mobile {
            max-width: 80%;
        }
        @include small-mobile {
            max-width: 90%;
        }
        
        h3 {
            color: $white;
            text-transform: uppercase;
            margin-bottom: 20px;
        }
        
        h1 {
            color: $white;
            max-width: 75%;
            margin: auto;
            
            @include tablet {
                max-width: 90%;
            }
            @include mobile {
                max-width: 100%;
            }
            @include small-mobile {
                max-width: 100%;
            }
            
            span {
                font-family: $cera-bold;
                text-transform: uppercase;
            }
            strong {
                font-family: $cera-bold;
                text-transform: uppercase;
            }
        }
    }
}