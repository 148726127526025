h1,h2,h3,h4,h5,h6,p {
    margin: 0;
    padding: 0;
    font-variant-numeric: lining-nums;
}
h1 { // Main Titles
    font-family: $cera-book;
  	font-size: 60px;
  	font-weight: 400;
    color: $base;
    letter-spacing: .01em;
    line-height: 1em;
    
    @include mobile {
        font-size: 50px;
    }
    @include small-mobile {
        font-size: 48px;
    }
} 
h2 { // Section Titles
    font-family: $cera-book;
    font-size: 45px;
    font-weight: 400;
    color: $base;
    letter-spacing: .01em;
    line-height: 1em;
    
    @include mobile {
        font-size: 40px;
    }
    @include small-mobile {
        font-size: 35px;
    }
}
h3 { // Dates
    font-family: $cera-medium;
    font-size: 36px;
    font-weight: 400;
    color: $base;
    letter-spacing: .01em;
    line-height: 1em;
    
    @include mobile {
        font-size: 34px;
    }
    @include small-mobile {
        font-size: 32px;
    }
}
h4 { // Subtitles
    font-family: $cera-bold;
    font-size: 18px;
    font-weight: 400;
    color: $base;
    letter-spacing: .01em;
    line-height: 1em;
    
    @include small-mobile {
        font-size: 17px;
    }
}
h5 { // Buttons
    font-family: $cera-medium;
    font-size: 18px; 
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .01em;
    line-height: 1em;
    
    @include small-mobile {
        font-size: 17px;
    }
}
h6 { // Misc
    font-family: $cera-medium;
    font-size: 20px;
    font-weight: 400;
    color: $base;
    letter-spacing: .01em;
    line-height: 1em;
    
    @include small-mobile {
        font-size: 18px;
    }
}
p { // Body
    font-family: $body;
    color: $base;
    font-size: 22px;
    font-weight: 300;
    line-height: 1.4em;
    margin-bottom: 30px;
    
    @include mobile {
        font-size: 20px;
    }
    @include small-mobile {
        font-size: 20px;
    }
}
b {
    font-weight: 600;
}
strong {
    font-weight: 600;
}
a:link {
    color: $base;
}
a:visited {
    color: $base;
}
a:hover {
    color: $base;
}
a:active {
    color: $base;
}
