#post {
    background: $base;
    
    h1 {
        color: $white;
        margin: 10vh auto;
        text-align: center;
        max-width: 70%;
        
        @include mobile {
            max-width: 80%;
        }
        @include small-mobile {
            max-width: 90%;
        }
    }
    .utilityBar {
        max-width: 75%;
        margin: 0 auto 20px auto;
        
        @include small-desktop {
            max-width: 80%;
        }
        @include tablet {
            max-width: 85%;
        }
        @include mobile {
            max-width: 100%;
        }
        @include small-mobile {
            max-width: 100%;
        }
        
        h6 {
            color: $white;
            text-transform: uppercase;
        }
        .subscribe {
            float: left;
        }
        .date {
            float: right;
            text-align: right;
        }
    }
    .postBody {
        background: $white;
        padding: 40px;
        max-width: 75%;
        margin: 0 auto 100px auto;
        
        @include small-desktop {
            max-width: 80%;
        }
        @include tablet {
            max-width: 85%;
        }
        @include mobile {
            padding: 30px;
            margin-bottom: 60px;
            max-width: 100%;
        }
        @include small-mobile {
            padding: 30px;
            margin-bottom: 40px;
            max-width: 100%;
        }
        
        .gallery {
            margin-bottom: 30px;
        }
        .video {
            margin-bottom: 30px;
        }
        a {
            color: $blue;
            
            &:hover {
                color: $red;
            }
        }
        
        .share {
            margin: 40px 0 0 0;
            cursor: pointer;
            transition: $transition;
            
            &:hover {
                opacity: .7;
            }
            img {
                max-width: 20px;
                display: inline-block;
                margin-right: 4px;
            }
            h6 {
                color: #999;
                display: inline-block;
                text-transform: uppercase;
                position: relative;
                top: 4px;
            }
        }
    }
    .micromodal-slide {
        display: none;
        &.is-open {
            display: block;
        }
    }
}