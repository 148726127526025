.sponsorCard {
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $white;
    border: 2px solid $greyD;
    margin-top: 30px;
    height: 180px;
    width: 100%;
    transition: $transition;
    
    @include mobile {
        height: 350px;
        max-width: 75%;
        margin: 30px auto 0 auto;
    }
    @include small-mobile {
        height: 230px;
    }
    
    &:hover {
        border-color: $pink;
    }
}