#mobileNav {
    display: none;
    
    .logo {
        display: none;
    }
    .share {
        display: none;
    }
    .navItems {
        float: none;
        top: 0;
        
        &.link {
            padding: 0;
        }
    }
}
#menu {
    background: #FFF;
    z-index: 2;
    .navItems {
        li {
            h5 {
                color: $base;
                text-transform: uppercase;
            }
        }
    }
    /*.navItems {
        display: block;
        float: left;
        margin-top: 100px;
        margin-left: 30px;
        a {
          font-weight: 600;
          display: block;
          padding: 15px 30px;
          text-transform: uppercase;
        }
        li {
          text-align: left;
          width: 100%;
          h5 {
            font-weight: 600;
              font-size: 20px;
          }
          &.contact {
            .button.pillButtonSmall {
              color: #fff;
              background: $base;
              border-radius: 50px;
              margin: 0;
              padding: 10px 12px 8px 12px;
            }
          }
        }
    }*/
}
.mobile-toggle {
    display: none;

    @include tablet {
        display: block;
    }
    @include mobile {
        
    }
    @include small-mobile {
       
    }
}