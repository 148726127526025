// Share Modal
.socialShare {
    position: relative;
        //z-index: 850;
        text-align: center;
        .modal__overlay {
            background: rgba(32, 47, 76, 0.9);
        }
        .modal__container {
            background: none;
            .modal__title {
                color: #FFF;
                text-align: center;
                font-size: 42px;
                margin-top: 30px;
            }
            .button {
                color: #FFF;
                display: inline-block;
                margin: 20px;
                font-size: 12px;
                img {
                    display: block;
                    position: relative;
                    height: 50px;
                    width: 50px;
                    box-shadow: none;
                    margin: 0 auto 10px auto;
                    left: 0;
                }
                h6 {
                    text-align: center;
                    color: $white;
                }
            }
            .copyButton {
                button {
                    background: #FFF;
                    border: none;
                    font-family: $cera-bold;
                    font-size: 14px;
                    padding: 15px 25px;
                    border-radius: 50px;
                    margin-top: 20px;
                    white-space: nowrap;
                    overflow: hidden;
                    max-width: 60vw;   
                    text-overflow: ellipsis;
                }
                
                .cpyBtn {
                    margin-bottom: 0;
                    img {
                        margin-bottom: 0;
                    }
                }
            }
            .copyMessage {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                text-align: center;
                h4 {
                    color: $white;
                    text-transform: none;
                    font-size: 16px;
                    font-style: italic;
                }
            }
        }
    }