// Share Modal
.postEvent {
    position: relative;
        //z-index: 850;
        text-align: center;
        .modal__overlay {
            background: $base;
        }
        .modal__container {
            //border-radius: 5px;
            overflow: scroll;
            max-height: 100%;
            max-width: 70%;
            
            @include tablet {
                max-width: 85%;
            }
            @include mobile {
                max-width: 100%;
            }
            @include small-mobile {
                max-width: 100%;
            }
            .modal__title {
                color: $white;
                text-align: center;
                font-size: 40px;
                max-width: 75%;
                margin: 40px auto;
                
                @include tablet {
                    max-width: 80%;
                }
                @include mobile {
                    max-width: 90%;
                }
                @include small-mobile {
                    max-width: 100%;
                }
            }
            p {
                color: $white;
            }
        }
        button {
            border: none;
            color: $base;
            padding: 5px;
            position: absolute;
            top: 20px;
            right: 20px;
            background: none;
            margin-bottom: 40px;
            img {
                height: 30px;
                width: 30px;
            }
        }
    }