#home {
    
    //global
    .intro {
        text-align: center;
        max-width: 75%;
        margin: auto;
        
        @include tablet {
            max-width: 80%;
        }
        @include mobile {
            max-width: 90%;
        }
        @include small-mobile {
            max-width: 100%;
        }
        
        .subtitle {
            margin-bottom: 20px;
        }
        h2 {
            max-width: 75%;
            margin: 0 auto 40px auto;
            
            @include tablet {
                max-width: 80%;
            }
            @include mobile {
                max-width: 85%;
            }
            @include small-mobile {
                max-width: 100%;
            }
        }
    }
    
    #about {
        padding-top: 150px;
        
        @include mobile {
            padding-top: 100px;
        }
        @include small-mobile {
            padding-top: 80px;
        }
        
        .intro {
            max-width: 65%;
            
            @include tablet {
                max-width: 80%;
            }
            @include mobile {
                max-width: 90%;
            }
            @include small-mobile {
                max-width: 100%;
            }
            
            a {
                color: $blue;
                
                &:hover {
                    color: $red;
                }
            }
            .subtitle {
                color: $red;
            }
        }
    }
    #events {
        padding-top: 150px;
        margin-bottom: 150px;
        
        @include mobile {
            padding-top: 100px;
            margin-bottom: 100px;
        }
        @include small-mobile {
            padding-top: 80px;
            margin-bottom: 100px;
        }
        
        .intro {
            text-align: center;
            
            .subtitle {
                color: $green;
            }
        }
        .tabs {
            margin-bottom: 50px;
            .tab {
                text-align: center;
                background: transparent;
                color: $base;
                border: 3px solid $base;
                border-radius: 50px;
                width: 75%;
                margin-bottom: 15px;
                padding: 12px 0;
                transition: $transition;
                cursor: pointer;
                
                &.active {
                    background: $base;
                    color: $white;
                }
                &:hover {
                    color: $green;
                    border-color: $green;
                    background: transparent;
                }
                
                @include tablet {
                    margin: 0 auto 15px auto;
                }
                @include mobile {
                    margin: 0 auto 15px auto;
                }
                @include small-mobile {
                    margin: 0 auto 15px auto;
                }
            }
        }
    }
    #volunteer {
        .content {
            max-width: 60%;
            box-shadow: $shadow;
            padding: 70px 50px;
            
            @include small-desktop {
                max-width: 75%;
                padding: 60px 40px;
            }
            @include tablet {
                max-width: 80%;
                padding: 60px 40px;
            }
            @include mobile {
                max-width: 90%;
                padding: 50px 30px;
            }
            @include small-mobile {
                max-width: 100%;
                padding: 45px 30px;
            }
            
            h2 {
                font-family: $cera-bold;
                font-size: 80px;
                text-transform: uppercase;
                margin-bottom: 40px;
                line-height: .9em;
                
                @include small-desktop {
                    font-size: 65px;
                }
                @include tablet {
                    font-size: 55px;
                }
                @include mobile {
                    font-size: 45px;
                }
                @include small-mobile {
                    font-size: 36px;
                }
            }
            p {
                margin: 0;
            }
        }
    }
    #news {
        background: $base;
        padding: 150px 0;
        
        @include mobile {
            padding: 100px 0 60px 0;
        }
        @include small-mobile {
            padding: 80px 0 50px 0;
        }
            
        .intro {
            text-align: center;
            
            .subtitle {
                color: $blue;
            }
            h2 {
                color: $white;
            }
        }
    }
    #sponsors {
        padding-top: 150px;
        
        @include mobile {
            padding-top: 100px;
        }
        @include small-mobile {
            padding-top: 80px;
        }
        
        .intro {
            text-align: center;
            margin-bottom: 20px;
            
            .subtitle {
                color: $pink;
            }
            .pillButtonDark {
                width: 280px;
                margin: 0 auto;
                border: none;
                
                &:hover {
                    border: none;
                    background: $pink;
                    color: $white;
                }
            }
            /*h5 {
                transition: $transition;
                
                &:hover {
                    color: $pink;
                }
            }*/
        }
    }
    #contact {
        padding-top: 150px;
        margin-bottom: 150px;
        
        @include mobile {
            padding-top: 100px;
            margin-bottom: 100px;
        }
        @include small-mobile {
            padding-top: 80px;
            margin-bottom: 100px;
        }
        
        .intro {
            text-align: center;
            margin-bottom: 30px;
            
            .subtitle {
                color: $yellow;
            }
        }
        .tabs {
            text-align: center;
            
            .tab {
                text-align: center;
                background: transparent;
                color: $base;
                border: 3px solid $base;
                border-radius: 50px;
                width: 20%;
                margin-bottom: 15px;
                padding: 12px 0;
                display: inline-block;
                margin: 0 10px;
                transition: $transition;
                cursor: pointer;
                
                &.active {
                    background: $base;
                    color: $white;
                }
                &:hover {
                    color: $yellow;
                    border-color: $yellow;
                    background: transparent;
                }
                
                @include tablet {
                    width: 28%;
                }
                @include mobile {
                    display: block;
                    width: 75%;
                    margin: 0 auto 15px auto;
                }
                @include small-mobile {
                    display: block;
                    width: 75%;
                    margin: 0 auto 15px auto;
                }
            }
        }
        .tab-content {
            max-width: 50%;
            margin: 60px auto 20px auto;
            
            @include tablet {
                max-width: 75%;
            }
            @include mobile {
                max-width: 85%;
            }
            @include small-mobile {
                max-width: 100%;
            }
            
            .sponsorPacket {
                text-align: center;
                margin-bottom: 40px;
                transition: $transition;
                
                &:hover {
                    color: $yellow;
                }
            }
        }
    }
    
}