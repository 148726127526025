#stickyNav {
    padding: 14px 30px;
    background: $base;
    position: fixed;
    top: 20px;
    right: 0;
    box-shadow: $shadow;
    border-radius: 100px 0 0 100px;
    z-index: 998;
    transition: all .12s ease-in-out;
    opacity: 0;
    display: none;
    .nav {
        @include tablet {
            display: none;
        }
        @include mobile {
            display: none;
        }
        @include small-mobile {
            display: none;
        }
    }
    .logo {
        display: none;
    }
    .navItems {
        float: none;
        top: 0;
        
        &.link {
            padding: 0;
        }
    }
    .menuToggle {
        display: none;
    }
    
    &.pushy-open-right {
      display: none;
    }
    @include tablet {
        display: block!important;
        opacity: 1!important;
        .menuToggle {
            display: block;
        }
    }
    @include mobile {
        display: block!important;
        opacity: 1!important;
        position: fixed !important;
        top: 20px;
        .menuToggle {
            display: block;
        }
    }
    @include small-mobile {
        display: block!important;
        opacity: 1!important;
        
        .menuToggle {
            display: block;
        }
    }
    .nav-icon2 {
        display: block;
        width: 30px;
        height: 25px;
        position: relative; 
        top: 0;
        left: -8px;
        margin: 10px auto;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
        cursor: pointer;
        span {
            display: block;
            position: absolute;
            height: 5px;
            width: 100%;
            background: #FFF;
            border: none;
            border-radius: 8px;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .25s ease-in-out;
            -moz-transition: .25s ease-in-out;
            -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out;
        }
        &:nth-child(even) {
            left: 50%;
            border-radius: 0 9px 9px 0;
        }
        &:nth-child(odd) {
            left:0px;
            border-radius: 9px 0 0 9px;
        }
    }

    .nav-icon2 span:nth-child(1), .nav-icon2 span:nth-child(2) {
      top: 0px;
    }

    .nav-icon2 span:nth-child(3), .nav-icon2 span:nth-child(4) {
      top: 11px;
    }

    .nav-icon2 span:nth-child(5), .nav-icon2 span:nth-child(6) {
      top: 22px;
    }

    .nav-icon2.open span:nth-child(1),.nav-icon2.open span:nth-child(6) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .nav-icon2.open span:nth-child(2),.nav-icon2.open span:nth-child(5) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    .nav-icon2.open span:nth-child(1) {
      left: 2px;
      top: 6px;
    }

    .nav-icon2.open span:nth-child(2) {
      left: calc(50% - 2px);
      top: 6px;
    }

    .nav-icon2.open span:nth-child(3) {
      width: 1px;
      left: 13px;
      opacity: 0;
    }

    .nav-icon2.open span:nth-child(4) {
      left: -100%;
      opacity: 0;
    }

    .nav-icon2.open span:nth-child(5) {
      left: 2px;
      top:17px;
    }

    .nav-icon2.open span:nth-child(6) {
      left: calc(50% - 2px);
      top: 17px;
    }
}
.pushy-open-right {
  #stickyNav {
    display: none;
  }
}