/*----BUTTONS----*/
.button {
    display: block;
    position: relative;
    text-align: center;
    cursor: pointer;
    transition: $transition; 
    text-decoration: none;
    outline: none;
    border: none;
    box-shadow: none;
    
    &.pillButtonLarge {
        background: $white;
        border: solid 3px $white;
        border-radius: 50px;
        width: 240px;
        margin: 40px auto 0 auto;
        padding: 14px;
        
        &:hover {
            background: transparent;
            color: $white;
        }
    }
    &.pillButtonSmall {
        background: rgba(255, 255, 255, 0.25);
        border-radius: 50px;
        margin: 0;
        padding: 8px 12px 6px 12px;
        float: both;
        
        &:hover {
            background: rgba(255, 255, 255, 1);
            color: $base;
        }
    }
    &.pillButtonDark {
        background: $base;
        border: solid 3px $base;
        color: $white;
        border-radius: 50px;
        width: 200px;
        margin: 40px 0 0 0;
        padding: 14px;
        
        &:hover {
            background: transparent;
            color: $base;
        }
    }
    &.signUp {
        background: $base;
        border: none;
        color: $white;
        border-radius: 50px;
        width: 200px;
        margin: 40px auto 0 auto;
        padding: 14px;
        
        &:hover {
            background: $red;
            color: $white;
        }
    }
    &.pressKit {
        //display: none;
        background: rgba(0, 0, 0, 0.1);
        color: $white;
        padding: 12px;
        border-radius: 3px;
        position: absolute;
        left: 50px;
        bottom: 50px;
        
        &:hover {
            background: rgba(0, 0, 0, 0.25);
        }
    }
}