/*------------
    FORMS
------------*/ 
#form {
}

/*------------ 
    LABELS
------------*/ 
label {  
    display: block;
    font-size: 14px; 
    color: $base; 
    font-weight: 600; 
    text-transform: uppercase; 
    margin: 0 20px 0 20px; 
}

/*-------------------------
    GENERAL INPUT STYLES
--------------------------*/ 
input[type=text] {
    font-family: $body!important;
    color: $base!important; 
    font-size: 16px!important;
    font-weight: 400!important;
    background: $greyE!important;
    border: 2px solid $greyE!important;
    border-radius: 50px!important;
    outline: none!important;
    display: block;
    margin-bottom: 30px;
    padding: 20px!important;
    width: 100%;
    transition: $transition!important;
}
input[type=email] { 
    @extend input[type=text];
}
button[type=submit] {
    font-family: $body!important;
    background: $base;
    border: none;
    border-radius: 50px;
    color: $white;
    display: block;
    font-size: 16px;
    font-weight: 600;
    margin: 25px auto;
    padding: 16px;
    text-transform: uppercase;
    text-align: center;
    width: 50%;
    transition: $transition;
    
    &:hover {
        background: $yellow;
    }
}
input.parsley-error,
textarea.parsley-error {
    border: solid red 2px !important;
}
h3.parsley-error {
    label {
        color: red;
    }
}

/*--------------
    TEXT AREA
---------------*/ 
textarea { 
    @extend input[type=text];
    border-radius: 20px!important;
    height: 220px;
    resize: none!important;
    color: $base;
}

//////////////
//  RADIOS 
//////////////
.radios {
    /* The wrapper */
    margin-bottom: 30px;
    padding-left: 20px;
    label {
        width: auto; 
        margin-right: 20px; 
        text-transform: none;
    }
        .wrapper {
            position: relative;
            margin: 5px 0 20px 0;
            padding-left: 30px;
            cursor: pointer;
            font-size: 16px; 
            color: $base; 
            font-family: $cera-book; 
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            transition: $transition;
            
            .unavail {
                text-decoration: line-through;
            }
        }
        /* Hide the browser's default radio button */
        .wrapper input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }
        /* Create a custom radio button */
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            border: 1px solid $greyD;
            width: 20px;
            border-radius: 50%;
            background-color: $greyD;
            transition: $transition;
        }
        /* When the radio button is checked, add a blue background */
        .wrapper input:checked ~ .checkmark {
            background-color: $base;
        }
    }

/*--------------------
FORM VALIDATION
--------------------*/
.error-message {
    display: none;
}
#validations {
    font-family: $body;
    font-size: 16px;
    font-style: italic;
    font-weight: 600;
    text-align: center;
    #thanks {
        display: none;
        color: $blue;
    }
    #errorMessage {
        display: none;
        color: $red;
    }
}

#mc_embed_signup input.email {
    padding: 22px!important;
    width: 320px!important;
    margin-bottom: 10px!important;
    
    @include mobile {
        width: 100%!important;
    }
    @include small-mobile {
        width: 100%!important;
    }
}
#mc-embedded-subscribe {
    background: $base!important;
    border-radius: 50px!important;
    font-family: $cera-bold!important;
    text-transform: uppercase!important;
    height: 46px!important;
    font-size: 16px!important;
    padding: 0 26px!important;
    
    &:hover {
        background: $red!important;
    }
}