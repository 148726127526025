/* BASE */
@import 'base/_variables.scss'; 
@import 'base/_mixins.scss';
@import 'base/_base.scss';
@import 'base/_typography.scss';
@import 'base/_buttons.scss';
@import 'base/_inputs.scss';  
@import 'base/_pushy.scss';  

/* MODULES */
@import 'modules/normalize.css';
@import 'modules/_bootstrap.scss';
@import 'modules/hover.css';
@import 'modules/_animate.min.css';

/* LAYOUTS */
@import 'layouts/_header.scss';
@import 'layouts/_nav.scss';
@import 'layouts/_mobileNav.scss';
@import 'layouts/_stickyNav.scss';
@import 'layouts/_footer.scss';
@import 'layouts/_sponsorCard.scss';
@import 'layouts/_modals.scss';
@import 'layouts/_shareModal.scss';
@import 'layouts/_postEventModal.scss';
@import 'layouts/_eventCard.scss';
@import 'layouts/_newsCard.scss';

/* PAGES */
@import 'pages/_home.scss';
@import 'pages/_newsPost.scss';