/*---------------
  FONTS
---------------*/
$cera-light: "cera-light", sans-serif;
$cera-lighti: "cera-lighti", sans-serif;
$cera-book: "cera-book", sans-serif;
$cera-booki: "cera-booki", sans-serif;
$cera-medium: "cera-medium", sans-serif;
$cera-bold: "cera-bold", sans-serif;
$body: 'Montserrat', sans-serif;
 
/*---------------   
  COLORS   
---------------*/ 
$base: #202F4C;
$red: #EF2E2F;
$green: #93CF4C;
$blue: #65D1EE;
$pink: #DC5CBF;
$yellow: #FFBC00;
$orange: #FC8015;
$purple: #7F207A;
$greyB: #BBB;
$greyD: #DDD;
$greyE: #EFEFEF;
$white: #FFF; 
$black: #000; 

/*---------------
  MEDIA QUERIES
---------------*/
$mobile-width: 480px;
$tablet-width: 768px;
$small-desktop-width: 992px;
$desktop-width: 1200px;
$large-desktop-width: 1440px;

/*---------------
  GENERAL
---------------*/
$shadow: 1px 1px 5px 0px rgba(0,0,0,0.1);
$transition: all .5s ease-in-out;